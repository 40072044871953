import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { Box } from '@wh-components/core/Box/Box'
import React, { Fragment, FunctionComponent } from 'react'
import { FlexboxProps } from '@wh-components/system/flexbox'
import { usePayliveryNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/usePayliveryNavigator'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { MarginProps } from '@wh-components/system/space'
import { Text } from '@wh-components/core/Text/Text'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'

interface PayliveryExperimentLabelProps {
    payliveryBadgeText?: string
}

export const PayliveryNavigator: FunctionComponent<
    NavigatorProps & FlexboxProps & PayliveryExperimentLabelProps & { showLabel?: boolean }
> = ({ navigator, taggingData, showLabel = true, onSearch, payliveryBadgeText = 'PayLivery' }) => {
    const { toggleState, toggleToggle } = usePayliveryNavigator(navigator, onSearch, taggingData, 'search_result_list_paylivery_checkbox')
    const navigatorLabelId = `navigator-title-${navigator.id}`

    return (
        <Fragment>
            {showLabel && <NavigatorLabel id={navigatorLabelId}>{navigator.label}</NavigatorLabel>}
            <Box display="flex" marginTop={{ phone: 's', tablet: 'inherit' }}>
                <Checkbox
                    id="paylivery-navigator-checkbox"
                    testId="paylivery-navigator-checkbox"
                    onChange={toggleToggle}
                    checked={toggleState}
                    label={<PayliveryNavigatorLabel payliveryBadgeText={payliveryBadgeText} />}
                />
            </Box>
        </Fragment>
    )
}

export const PayliveryNavigatorLabel: FunctionComponent<MarginProps & PayliveryExperimentLabelProps> = (props) => {
    return (
        <Box display="flex" justifyContent="center" marginBottom={0} {...props}>
            <Text color="palette.verydarkgrey" fontSize="m">
                nur Anzeigen {props.payliveryBadgeText === 'PayLivery' ? 'mit' : ''}
            </Text>
            <Box marginLeft="xs">
                <PayliveryBadge id="paylivery-navigator" text={props.payliveryBadgeText} fontSize="xxs" />
            </Box>
        </Box>
    )
}
