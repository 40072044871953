import { SearchId } from '@bbx/common/api/dtos/search-id'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { Sorting } from '@bbx/search-journey/sub-domains/search/components/common/common/Sorting/Sorting'
import { BapNavigatorGroup } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BapNavigatorGroup'
import { Box } from '@wh-components/core/Box/Box'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent, RefObject, useRef } from 'react'
import { css } from 'styled-components'
import { SaveSizeFakeDoor } from '../SaveSizeFakeDoor/SaveSizeFakeDoor'

interface NavigatorsProps {
    searchResult: SearchResult
    onSearch: SearchCallback
    abortRequest: () => void
    searchId: SearchId
    taggingData: TaggingData
    scrollRootRef: RefObject<HTMLDivElement> | undefined
}

const BapNavigators: FunctionComponent<NavigatorsProps & { payliveryBadgeText?: string }> = ({
    searchResult,
    onSearch,
    searchId,
    taggingData,
    scrollRootRef,
    abortRequest,
    payliveryBadgeText = 'PayLivery',
}) => {
    // only start observing navigator groups when the filter has been in the viewport once
    // this is relevant for phone where a scrollRootRef is set (root for intersection observer) which would trigger intersection even if the filter overlay is hidden and moved to the left outside of the viewport
    // therefore 0px rootMargin
    const containerRef = useRef<HTMLDivElement>(null)
    const [containerWasInViewportOnce] = useIsInView(containerRef, '0px')

    return (
        <Box
            ref={containerRef}
            css={css`
                ${(p) => p.theme.media.tablet} {
                    > *:not(:last-child) {
                        margin-bottom: ${(p) => p.theme.space.l}px;
                    }
                }
            `}
        >
            <SaveSizeFakeDoor
                slot="filter"
                searchResult={searchResult}
                marginHorizontal={{ phone: 'm', tablet: '0' }}
                marginBottom={{ phone: 'm', tablet: '0' }}
                marginTop={{ phone: 'm', tablet: 's' }}
            />
            {searchResult.navigatorGroups.map((group, index) => (
                <DynamicRendering<HTMLDivElement, HTMLDivElement | undefined>
                    forceRendering={index < 2}
                    disableIntersectionChecking={scrollRootRef && !containerWasInViewportOnce}
                    key={`${index}${group.label}`}
                    // placeholder has height for improved UX in filter sidebar on Small + for intersection observer not triggering for all placeholders at once
                    renderPlaceholder={(ref) => (
                        <Box height={200} testId={`navigator-group-${group.label.replace(/\s/g, '')}-placeholder`} ref={ref} />
                    )}
                    scrollRootRef={scrollRootRef}
                >
                    <BapNavigatorGroup
                        group={group}
                        onSearch={onSearch}
                        abortRequest={abortRequest}
                        searchId={searchId}
                        taggingData={taggingData}
                        key={group.label}
                        autocompleteContextLink={findContextLinkWithId('autocomplete', searchResult.autocompleteLinkList)}
                        payliveryBadgeText={payliveryBadgeText}
                    />
                </DynamicRendering>
            ))}
            {searchResult.sortOrderList.contextLink.length > 0 && (
                <Box display={{ tablet: 'none' }} padding="m">
                    <NavigatorLabel>Sortierung</NavigatorLabel>
                    <Sorting
                        marginTop="s"
                        onSearch={onSearch}
                        sortOrderList={searchResult.sortOrderList}
                        testId="sorting-select-in-filters"
                        size="large"
                        taggingEvent="search_result_list_sortorder_filter"
                        taggingEventNearMe="search_result_list_sortorder_filter_nearme"
                    />
                </Box>
            )}
        </Box>
    )
}

export const MemoizedBapNavigators = React.memo(BapNavigators)
