import React, { FunctionComponent } from 'react'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { Box } from '@wh-components/core/Box/Box'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { getFlatPossibleValues, getValueId } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import ArrowLeft from '@wh-components/icons/ArrowLeft'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import ArrowRight from '@wh-components/icons/ArrowRight'
import { scrollToElement } from '@wh/common/chapter/lib/commonHelpers'
import { buildParentList } from '@bbx/search-journey/sub-domains/search/lib/buildParentList'
import { CategoryRow } from '@bbx/search-journey/sub-domains/search/components/common/common/CategoryRow/CategoryRow'
import { PossibleLabelContent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorValue'

interface CategoryNavigatorProps extends NavigatorProps {}

export const CategoryNavigator: FunctionComponent<CategoryNavigatorProps> = ({ navigator, onSearch, taggingData }) => {
    const navigatorLabelId = `navigator-title-${navigator.id}`

    const parents = buildParentList(navigator.selectedValues)
    const disabled = navigator.navigatorSelectionType === 'NOT_SELECTABLE'
    const color = disabled ? 'palette.grey' : 'palette.verydarkgrey'

    return (
        <Box testId={`navigator-${navigator.id}-wrapper`}>
            <NavigatorLabel id={navigatorLabelId}>{navigator.label}</NavigatorLabel>
            <Box marginBottom={{ phone: 's', tablet: 0 }} />
            {parents.map((currentParent) => {
                return (
                    <CategoryRow
                        paddingLeft="xl"
                        key={currentParent.label}
                        testId={`navigator-category-${currentParent.label}-parent`}
                        onClick={() => {
                            if (disabled) {
                                return
                            }
                            callActionEvent('search_result_list_category', taggingData)
                            onSearch(currentParent.link!)
                        }}
                    >
                        <ButtonWithLinkStyle
                            color={color}
                            disabled={disabled}
                            css={css`
                                text-align: left;
                                white-space: normal;
                                ${disabled
                                    ? css`
                                          &:hover {
                                              text-decoration: none;
                                          }
                                          pointer-events: none;
                                      `
                                    : undefined}
                            `}
                        >
                            <ArrowLeft />

                            <Text display="block" fontSize="m" color={color} marginLeft="xs">
                                {currentParent.label}
                            </Text>
                        </ButtonWithLinkStyle>
                    </CategoryRow>
                )
            })}
            {navigator.selectedValues.length > 0 && (
                <CategoryRow paddingLeft="xl" color="palette.primary.main" fontWeight="bold" testId="category-navigator-selected-category">
                    {navigator.selectedValues[0].label}
                </CategoryRow>
            )}

            <Box marginBottom="xs">
                {getFlatPossibleValues(navigator).map((possibleValue) => {
                    const possibleValueButtonId = `navigator-title-${navigator.id.replace(/\s/g, '')}-${possibleValue.label.replace(
                        /\s/g,
                        '',
                    )}`
                    const combinedAriaLabelledBy = ['Kategorie', possibleValueButtonId].filter(Boolean).join(' ')
                    const isRootCategory = possibleValue.parent === null
                    return (
                        <CategoryRow
                            paddingLeft={isRootCategory ? 'xl' : 'xxxl'}
                            key={getValueId(possibleValue)}
                            onClick={async (e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                callActionEvent('search_result_list_category', taggingData)
                                await onSearch(possibleValue.searchLink)
                                scrollToElement(document.getElementById(navigatorLabelId), 'start', 'if-needed', undefined, 'auto')
                            }}
                        >
                            <a
                                href={possibleValue.webLink?.relativePath ?? undefined}
                                id={possibleValueButtonId}
                                css={css`
                                    white-space: normal;
                                    text-decoration: none;
                                    text-align: left;
                                    display: inline-flex;
                                    cursor: pointer;
                                `}
                                aria-labelledby={combinedAriaLabelledBy}
                            >
                                <Box testId={`navigator-category-${possibleValue.label}-value`}>
                                    <PossibleLabelContent
                                        label={possibleValue.label}
                                        showHitsLabel={true}
                                        disabled={false}
                                        hits={possibleValue.hits}
                                    />
                                </Box>
                            </a>
                            <Box display={{ phone: 'flex', tablet: 'none' }} marginLeft="auto">
                                <ArrowRight />
                            </Box>
                        </CategoryRow>
                    )
                })}
            </Box>
        </Box>
    )
}
