import { AttributeSearchResult } from '@bbx/common/types/AttributeSearchResult'
import { fetcher } from '@wh/common/chapter/api/fetcher'

export const searchAttributes = (url: string, abortSignal?: AbortSignal, term?: string): Promise<AttributeSearchResult> => {
    return fetcher<AttributeSearchResult>(
        `/iad${url}${term ?? ''}`,

        {
            method: 'GET',
            signal: abortSignal,
        },
    )
}
