import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'

export const BapImageCheckboxContent: FunctionComponent<{ image: string | undefined }> = ({ image }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="calc(100% - 2*3px)"
            height="calc(100% - 2*3px)"
            margin="3px"
            borderRadius="2px"
        >
            {image && <ResponsiveImage height="100%" width="100%" src={image} />}
        </Box>
    )
}
