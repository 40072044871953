import { ContextLink } from '@bbx/common/types/contextLinks'
import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { ModalNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/ModalNavigator'
import { MultiSelectNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/MultiSelectNavigator'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { RangeNavigatorComponent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/RangeNavigatorComponent/RangeNavigatorComponent'
import { AutocompleteKeywordNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/AutocompleteKeywordNavigator'
import { BapColorNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/BapColorNavigator'
import { CategoryNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/CategoryNavigator'
import { PayliveryNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/PayliveryNavigator'
import { PriceNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/PriceNavigator'
import { getCheckboxContent } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/lib/getCheckboxContent'
import {
    isMultiSelectNavigator,
    isRangeNavigator,
    isSingleSelectNavigator,
    isTextNavigator,
    isTreeAttributeNavigator,
    navigatorContainsAtLeastXPossibleValues,
} from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { verticals } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent } from 'react'
import { SearchMultiselectModalNavigator } from '../../../../common/common/Navigators/MultiSelectNavigator/SearchMultiselectModalNavigator'
import { ProvinceNavigator } from '../../../../common/common/Navigators/ProvinceNavigator/ProvinceNavigator'
import { SearchAttributesMultiSelect } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/SearchAttributesMultiSelect'

export const BapNavigatorDiscriminator: FunctionComponent<
    NavigatorProps & {
        abortRequest: () => void
        districtNavigator: Navigator | RangeNavigator | undefined
        autocompleteContextLink?: ContextLink
        payliveryBadgeText: string
    }
> = ({
    navigator,
    districtNavigator,
    onSearch,
    abortRequest,
    searchId,
    'aria-labelledby': ariaLabelledBy,
    taggingData,
    autocompleteContextLink,
    payliveryBadgeText,
}) => {
    if (navigator.id === 'category') {
        return <CategoryNavigator navigator={navigator} onSearch={onSearch} aria-labelledby={ariaLabelledBy} taggingData={taggingData} />
    } else if (navigator.id === 'state') {
        return (
            <ProvinceNavigator
                navigator={navigator}
                districtNavigator={districtNavigator}
                onSearch={onSearch}
                searchId={searchId}
                label="Suche in Region"
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
                taggingEvent="search_result_list_show_all_regions"
                vertical={verticals.BAP}
            />
        )
    } else if (navigator.id === 'district') {
        return null
    } else if (navigator.id === 'dealer') {
        return <MultiSelectNavigator navigator={navigator} onSearch={onSearch} aria-labelledby={ariaLabelledBy} taggingData={taggingData} />
    } else if (isTextNavigator(navigator)) {
        return (
            <AutocompleteKeywordNavigator
                navigator={navigator}
                onSearch={onSearch}
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
                autocompleteContextLink={autocompleteContextLink}
                size="medium"
                shownInResultList={true}
                autocompleteTaggingEvent="search_result_list_keyword_search_autocomplete"
                lastSearchTaggingEvent="search_result_list_keyword_search_last"
                searchTaggingEvent="search_result_list_keyword_search_search"
            />
        )
    } else if (isSingleSelectNavigator(navigator)) {
        return <MultiSelectNavigator navigator={navigator} onSearch={onSearch} aria-labelledby={ariaLabelledBy} taggingData={taggingData} />
    } else if (navigator.id === 'Farbe') {
        // Farbe has also the navigatorValuesDisplayType == 'IMAGE' like below, but we want to have a special filter here
        return <BapColorNavigator navigator={navigator} onSearch={onSearch} aria-labelledby={ariaLabelledBy} taggingData={taggingData} />
    } else if (navigator.navigatorValuesDisplayType === 'IMAGE') {
        return (
            <ModalNavigator
                onSearch={onSearch}
                taggingData={taggingData}
                navigator={navigator}
                showHelpText={true}
                checkedContent={getCheckboxContent('svg-selected')}
                uncheckedContent={getCheckboxContent('svg-normal')}
                checkboxSize="touchscreen"
            />
        )
    } else if (navigator.id === 'paylivery') {
        return (
            <PayliveryNavigator
                navigator={navigator}
                onSearch={onSearch}
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
                payliveryBadgeText={payliveryBadgeText}
            />
        )
    } else if (navigator.id === 'Marke') {
        if (navigator.metadata?.attributeSearchLink) {
            return <SearchAttributesMultiSelect onSearch={onSearch} taggingData={taggingData} navigator={navigator} showHelpText={true} />
        } else {
            return (
                <SearchMultiselectModalNavigator onSearch={onSearch} taggingData={taggingData} navigator={navigator} showHelpText={true} />
            )
        }
    } else if (isMultiSelectNavigator(navigator)) {
        if (isTreeAttributeNavigator(navigator) && navigatorContainsAtLeastXPossibleValues(navigator, 5)) {
            // return null
            return <ModalNavigator onSearch={onSearch} taggingData={taggingData} navigator={navigator} showHelpText={true} />
        } else {
            return (
                <MultiSelectNavigator
                    navigator={navigator}
                    onSearch={onSearch}
                    aria-labelledby={ariaLabelledBy}
                    taggingData={taggingData}
                />
            )
        }
    } else if (navigator.id === 'price' && isRangeNavigator(navigator)) {
        return (
            <PriceNavigator
                navigator={navigator}
                abortRequest={abortRequest}
                onSearch={onSearch}
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
            />
        )
    } else if (isRangeNavigator(navigator)) {
        return (
            <RangeNavigatorComponent
                navigator={navigator}
                abortRequest={abortRequest}
                onSearch={onSearch}
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
            />
        )
    } else {
        return null
    }
}
