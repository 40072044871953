import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { useEffect, useState } from 'react'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'

export const usePayliveryNavigator = (
    navigator: Navigator | RangeNavigator,
    onSearch: SearchCallback,
    taggingData: TaggingData,
    taggingEvent: TaggingActionEvent,
) => {
    const [toggleState, setToggleState] = useState<boolean>(navigator.selectedValues.length > 0)

    // listens to navigator changes globally
    useEffect(() => {
        setToggleState(navigator.selectedValues.length > 0)
    }, [navigator])

    const toggleToggle = async () => {
        setToggleState(!toggleState)
        if (toggleState) {
            await onSearch(navigator.urlConstructionInformation.baseUrl)
        } else {
            callActionEvent(taggingEvent, taggingData)
            await onSearch(navigator.urlConstructionInformation.baseUrl, { paylivery: 'true' })
        }
    }
    return { toggleState, toggleToggle }
}
