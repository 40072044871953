import { CheckboxStateType } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import React, { FunctionComponent } from 'react'
import { ResetLinkButton } from '../ResetLinkButton/ResetLinkButton'
import { AttributeSearchResultItem } from '@bbx/common/types/AttributeSearchResult'

type SearchAttributesMultiSelectResetBubblesProps = {
    attributes: AttributeSearchResultItem[]
    checkboxState: CheckboxStateType
    onChange: (id: string, value: boolean) => void
    onReset: () => void
}

export const SearchAttributesMultiSelectResetBubbles: FunctionComponent<SearchAttributesMultiSelectResetBubblesProps> = ({
    attributes,
    checkboxState,
    onChange,
    onReset,
}) => {
    const anythingSelected = Object.keys(checkboxState).reduce((accumulator, current) => accumulator || checkboxState[current], false)

    return (
        <Box display="flex" flexWrap="wrap" gap="xs">
            {attributes
                .filter((attribute) => checkboxState[attribute.treeAttributeId.toString()])
                .map((attribute) => (
                    <ResetLinkButton
                        testId="search-attributes-multiselect-reset-bubbles"
                        key={attribute.treeAttributeId.toString()}
                        label={attribute.label.map((token) => token.token).join('')}
                        onClick={() => {
                            onChange(attribute.treeAttributeId.toString(), false)
                        }}
                    />
                ))}
            {anythingSelected && (
                <ButtonWithLinkStyle
                    testId="search-attributes-multiselect-reset-all-bubbles"
                    onClick={onReset}
                    marginLeft="xs"
                    fontWeight="bold"
                >
                    Filter zurücksetzen
                </ButtonWithLinkStyle>
            )}
        </Box>
    )
}
