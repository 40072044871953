import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { Toggle } from '@wh-components/core/FormElements/Toggle/Toggle'
import React, { Fragment, FunctionComponent } from 'react'
import {
    RangeNavigatorComponent,
    RangeNavigatorProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/RangeNavigatorComponent/RangeNavigatorComponent'
import { useGiveAwayToggle } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/Navigators/useGiveAwayToggle'

export const PriceNavigator: FunctionComponent<RangeNavigatorProps & BoxProps> = ({ navigator, onSearch, id = navigator.id, ...props }) => {
    const { toggleState, toggleToggle } = useGiveAwayToggle(navigator, onSearch)

    return (
        <Fragment>
            <RangeNavigatorComponent navigator={navigator} onSearch={onSearch} id={id} {...props} />
            <Box display="flex" marginTop="s">
                <Toggle
                    alignSelf="center"
                    id={`${id}-toggle`}
                    testId={`${id}-toggle`}
                    marginRight={{ tablet: 'xs' }}
                    onChange={toggleToggle}
                    checked={toggleState}
                    label={<Box marginLeft="sm">zu verschenken</Box>}
                />
            </Box>
        </Fragment>
    )
}
