import { AttributeSearchResultItem, FormattingToken } from '@bbx/common/types/AttributeSearchResult'
import { Navigator } from '@bbx/search-journey/common/Navigators'
import { CheckboxStateType } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import React, { Fragment, FunctionComponent } from 'react'
import { css } from 'styled-components'

type Props = {
    groupName?: string | null
    navigator: Navigator
    attributes: AttributeSearchResultItem[]
    checkboxState: CheckboxStateType
    onChange: (attributeId: string, checked: boolean) => void
}

export const SearchPossibleValues: FunctionComponent<Props> = ({ groupName, attributes, checkboxState, onChange, navigator }) => {
    const flexColumns = { phone: '0 1 100%', tablet: '0 1 50%', desktop: '0 1 33.33%' }

    const renderLabel = (label: FormattingToken[]) => {
        return label.map((token, index) => {
            if (token.bold) {
                return <b key={`${index}${token.token}`}>{token.token}</b>
            } else {
                return <Fragment key={`${index}${token.token}`}>{token.token}</Fragment>
            }
        })
    }

    const idPrefix = `${navigator.id}-${groupName}-attributes-checkbox-modal-`
    const checkboxCss = css`
        break-inside: avoid;

        @-moz-document url-prefix() {
            label:before {
                top: 0.5px;
            }
        }
    `

    const textCss = css`
        &:hover {
            text-decoration: underline;
        }
    `

    return (
        <Box marginBottom="s" testId={`modal-group-attributes-${groupName}-container`}>
            {groupName && (
                <Box marginBottom="xs">
                    <Heading level={3} fontSize="m" text={groupName} testId={`modal-group-attributes-${groupName}`} />
                </Box>
            )}
            <Box display="flex" flexWrap="wrap">
                {attributes.map((attribute) => {
                    const id = idPrefix + attribute.treeAttributeId
                    return (
                        <Checkbox
                            flex={flexColumns}
                            key={attribute.treeAttributeId}
                            label={
                                <Box paddingLeft={2}>
                                    <Text breakWord={true} paddingRight="s" color="palette.verydarkgrey" css={textCss}>
                                        {renderLabel(attribute.label)}
                                    </Text>

                                    {(attribute.hits ?? 0) > 0 && (
                                        <Text
                                            aria-label={`mit ${attribute.hits} Treffern`}
                                            breakWord={true}
                                            fontSize="s"
                                            color="palette.elephant"
                                        >
                                            {attribute.hits?.toLocaleString('de-DE')}
                                        </Text>
                                    )}
                                </Box>
                            }
                            marginBottom={{ phone: 'xs', tablet: 0 }}
                            id={id}
                            testId={id}
                            checked={checkboxState[attribute.treeAttributeId] || false}
                            onChange={(e) => onChange(attribute.treeAttributeId.toString(), e.target.checked)}
                            css={checkboxCss}
                            disabled={false}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}
