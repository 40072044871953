import styled, { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'

export const categoryRowCss = (borderColor?: string) => css`
    display: flex;
    align-items: center;
    padding-right: ${(p) => p.theme.space.m}px;
    padding-top: ${(p) => p.theme.space.xs}px;
    margin-left: -${(p) => p.theme.space.xl}px;
    margin-right: -${(p) => p.theme.space.m}px;

    ${(p) => p.theme.media.only.phone} {
        padding-top: ${(p) => p.theme.space.s}px;
        padding-bottom: ${(p) => p.theme.space.s}px;

        &:active {
            background-color: ${(p) => p.theme.colors.palette.babyseal};
        }

        border-top: 1px solid ${(p) => borderColor ?? p.theme.colors.palette.owl};

        &:last-of-type {
            border-bottom: 1px solid ${(p) => borderColor ?? p.theme.colors.palette.owl};
        }
    }
`

export const categoryDrilldownRowCss = (borderColor?: string) => css`
    ${categoryRowCss(borderColor)}
    &:last-of-type {
        border-bottom: 0;
    }
    padding-top: 0;
`

export const CategoryRow = styled(Box)`
    ${categoryRowCss()}
`
