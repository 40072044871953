import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Autocomplete, AutocompleteItems } from '@wh-components/core/FormElements/Autocomplete/Autocomplete'
import { NavigatorProps } from '../../../../common/common/Navigators/NavigatorProps'
import { getAutocompleteSuggestionsFromLink } from '@bbx/search-journey/common/api/autocompleteApiClient'
import { WidthProps } from '@wh-components/system/layout'
import { useIsiPhone } from '@wh/common/chapter/hooks/useIsiPhone'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { Box } from '@wh-components/core/Box/Box'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { BapAutocompleteEntry } from '@bbx/search-journey/common/BapAutocompleteResponse'
import { ResponsiveValue } from '@wh-components/system'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { InputSize } from '@wh-components/core/common'
import { useThrottledCallback } from 'use-debounce'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { SEARCH_RESULT_LAST_KEYWORDS } from '@wh/common/chapter/lib/localStorage'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { IconType } from '@wh-components/icons/utilities/createSvgIcon'
import { useScrollNavigatorInView } from '@bbx/common/hooks/useScrollNavigatorInView'
import { AutoCompleteItemType } from '@wh-components/core/FormElements/Autocomplete/AutoCompleteItemType'
import { useAbortController } from '@wh/common/chapter/api/useAbortController'

interface AutocompleteKeywordNavigatorProps extends NavigatorProps, WidthProps {
    onClear?: SearchCallback
    label?: string
    showResetLink?: boolean
    shownInResultList?: boolean
    size?: ResponsiveValue<InputSize>
    showLabel?: boolean
    autocompleteContextLink?: ContextLink
    placeholder?: string
    searchTaggingEvent: TaggingActionEvent
    autocompleteTaggingEvent: TaggingActionEvent
    lastSearchTaggingEvent: TaggingActionEvent
    secondaryIcon?: IconType
    secondaryIconAriaLabel?: string
    onSecondaryIconClick?: () => void
    testId?: string
}

interface Item extends AutoCompleteItemType {
    attributeTreeId?: number
    source: 'recentSearch' | 'autocomplete'
}

const KEYWORD_HISTORY_SIZE = 6

const selectedValueFromNavigatorValue = (selectedValues: SelectedNavigatorValue[]) => {
    if (!selectedValues || selectedValues.length === 0) {
        return ''
    }

    return selectedValues[0].label
}

export const loadLastKeywords = (): AutocompleteItems<Item> => {
    const lastKeywords = getLastKeywordsFromLocalStorage()
    if (lastKeywords.length) {
        return [
            {
                heading: 'Letzte Suchen',
                items: lastKeywords,
            },
        ]
    }
    return [{ items: [] }]
}

export const saveKeyword = (keyword: string) => {
    if (keyword.trim() && storageAvailable('localStorage')) {
        const lastKeywords = getLastKeywordsFromLocalStorage()
        const foundIndex = lastKeywords.findIndex((savedKeyword) => savedKeyword.label === keyword)
        if (foundIndex !== -1) {
            lastKeywords.splice(foundIndex, 1)
        }
        lastKeywords.unshift({ label: keyword, source: 'recentSearch', testId: keyword })
        localStorage.setItem(SEARCH_RESULT_LAST_KEYWORDS, JSON.stringify(lastKeywords.slice(0, KEYWORD_HISTORY_SIZE)))
    }
}

const getLastKeywordsFromLocalStorage = (): Item[] => {
    if (storageAvailable('localStorage')) {
        const object = localStorage.getItem(SEARCH_RESULT_LAST_KEYWORDS)
        const lastKeywords = object && (JSON.parse(object) as Item[])
        return lastKeywords || []
    }
    return []
}

export const AutocompleteKeywordNavigator: FunctionComponent<AutocompleteKeywordNavigatorProps> = ({
    navigator,
    onSearch,
    onClear = onSearch,
    width,
    minWidth,
    maxWidth,
    size,
    showLabel = true,
    shownInResultList,
    autocompleteContextLink,
    placeholder = 'Suchbegriff',
    taggingData,
    searchTaggingEvent,
    autocompleteTaggingEvent,
    lastSearchTaggingEvent,
    secondaryIcon,
    secondaryIconAriaLabel,
    onSecondaryIconClick,
    testId = 'suchbegriff',
}) => {
    const isiPhone = useIsiPhone()
    const ref = useRef<HTMLDivElement | null>(null)

    const [autocompleteItems, setAutocompleteItems] = useState<AutocompleteItems<Item>>(defaultResult)
    const [inputValue, setInputValue] = useState<string>(selectedValueFromNavigatorValue(navigator.selectedValues))
    const { createAbortSignal, abort } = useAbortController()
    const { scrollNavigatorInView } = useScrollNavigatorInView(ref)

    // listens to navigator changes globally
    useEffect(() => {
        setInputValue(selectedValueFromNavigatorValue(navigator.selectedValues))
    }, [navigator])

    const debouncedApiOnRequestAutocompleteItemsUpdate = useThrottledCallback(async (newInputValue: string) => {
        abort()

        if (!autocompleteContextLink) {
            setAutocompleteItems(defaultResult)
            return
        }

        const autocompleteResponse = await getAutocompleteSuggestionsFromLink(
            autocompleteContextLink,
            encodeURIComponent(newInputValue),
            createAbortSignal(),
        )

        if (!autocompleteResponse || autocompleteResponse.length === 0) {
            setAutocompleteItems(defaultResult)
            return
        }

        const filteredItems = autocompleteResponse.map((entry) => mapEntry(entry))

        setAutocompleteItems([{ items: filteredItems }])
    }, 300)

    const mapEntry = (entry: BapAutocompleteEntry): Item => {
        return {
            label: entry.phrase,
            category: entry.category ? `in ${entry.category.name}` : undefined,
            attributeTreeId: entry.category?.id,
            testId: entry.phrase.replace(/\s/g, ''),
            source: 'autocomplete',
        }
    }

    const debouncedOnRequestAutocompleteItemsUpdate = useCallback(
        (newInputValue: string) => {
            if (!newInputValue.trim()) {
                abort()
                debouncedApiOnRequestAutocompleteItemsUpdate.cancel()
                setAutocompleteItems(loadLastKeywords())
                return
            }
            debouncedApiOnRequestAutocompleteItemsUpdate(newInputValue)
        },
        [debouncedApiOnRequestAutocompleteItemsUpdate, abort],
    )

    const onChange = useCallback(
        async (newSelectedItem: Item) => {
            saveKeyword(newSelectedItem.label)
            const additionalParams: Record<string, string> = {}
            const paramName = navigator.urlConstructionInformation.urlParams[0].urlParameterName
            additionalParams[paramName] = newSelectedItem.label
            if (newSelectedItem.attributeTreeId) {
                additionalParams.ATTRIBUTE_TREE = String(newSelectedItem.attributeTreeId)
            }
            callActionEvent(newSelectedItem.source === 'autocomplete' ? autocompleteTaggingEvent : lastSearchTaggingEvent, taggingData)

            await onSearch(navigator.urlConstructionInformation.baseUrl, additionalParams, { loadCategorySuggestions: true })
        },
        [navigator, onSearch, taggingData, autocompleteTaggingEvent, lastSearchTaggingEvent],
    )

    const onChangeText = useCallback(
        async (newText: string) => {
            saveKeyword(newText)
            const additionalParams: Record<string, string> = {}
            const paramName = navigator.urlConstructionInformation.urlParams[0].urlParameterName
            additionalParams[paramName] = newText

            callActionEvent(searchTaggingEvent, taggingData)

            await onSearch(navigator.urlConstructionInformation.baseUrl, additionalParams, { loadCategorySuggestions: true })
        },
        [navigator, onSearch, taggingData, searchTaggingEvent],
    )

    const onClearInput = useCallback(async () => {
        await onClear(navigator.urlConstructionInformation.baseUrl)
    }, [navigator, onClear])

    return (
        <Box width={width} minWidth={minWidth} maxWidth={maxWidth}>
            {showLabel && (
                <Box marginBottom="xs">
                    <NavigatorLabel>{navigator.label}</NavigatorLabel>
                </Box>
            )}
            <Autocomplete
                id={navigator.id}
                testId={testId}
                ariaLabel={navigator.label}
                size={size}
                autocompleteItems={autocompleteItems}
                externalInputValue={inputValue}
                onRequestAutocompleteItemsUpdate={debouncedOnRequestAutocompleteItemsUpdate}
                onChange={onChange}
                onChangeText={onChangeText}
                onFocus={scrollNavigatorInView}
                onClear={onClearInput}
                autoComplete="off"
                placeholder={placeholder}
                containerRef={ref}
                drawMenuInFrontOfBorderWorkaround={{ phone: shownInResultList && isiPhone, tablet: false }}
                secondaryIcon={secondaryIcon}
                secondaryIconAriaLabel={secondaryIconAriaLabel}
                onSecondaryIconClick={onSecondaryIconClick}
            />
        </Box>
    )
}

const defaultResult: AutocompleteItems<Item> = []
