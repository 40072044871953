import { isNotUndefined } from './functionalHelpers'

export const matchesSearchText = (searchText: string, attributeValue: string): boolean => {
    const searchTextParts = relevantSearchStringParts(searchText)
    const attributeLabelParts = relevantSearchStringParts(attributeValue)
    return matchesSearchTextIndexed(searchTextParts, attributeLabelParts)
}

export const matchesSearchTextIndexed = (searchTextParts: string[], attributeLabelParts: string[]): boolean => {
    // match against the attribute label without spaces too, so we can e.g. match "obag" against "o bag", or "marco polo" against "marc o'polo"
    const attributeLabelWithoutSpaces = attributeLabelParts.filter((part) => part !== '&').join('')
    return searchTextParts.every((searchTextPart) =>
        attributeLabelParts.some(
            (attributeLabelPart) => attributeLabelPart.includes(searchTextPart) || attributeLabelWithoutSpaces.includes(searchTextPart),
        ),
    )
}

export const relevantSearchStringParts = (text: string) => {
    return (
        text
            .toLocaleLowerCase()
            // convert to unicode normal form, which converts diacritics like è to e`, so we can replace the backtick in the next line
            .normalize('NFD')
            // remove all diacritics
            .replace(/[\u0300-\u036f]/g, '')
            // this takes care about the case that the & has no spaces around (e.g. C&A), but we still want to match the text parts left and right of the & separately
            .replace('&', ' & ')
            .replace(/[`'´]/g, '')
            // this means we ignore all characters except letters and &, and make them searchable in any order
            .split(/[^\wäöüß&]/)
            // use und/and/& as aliases
            .map((s) => s.replace(/^(und|and)$/, '&'))
            .filter((s) => s.trim().length > 0)
    )
}

type StartIndex = number
// end index is exclusive
type EndIndex = number
type Range = [StartIndex, EndIndex]

export const splitStringToBoldParts = (inputString: string, boldTextPartString: string): { part: string; bold: boolean }[] => {
    const boldTextParts = relevantSearchStringParts(boldTextPartString)
    const lowercaseInputString = inputString
        .toLocaleLowerCase()
        // remove all diacritics - assumes the inputString did not contain any in non-normal form
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

    const matchingBoldRanges = boldTextParts
        .map((p) => {
            // to let e.g. "soliver" match "s.Oliver", we'll create a regex with every character of the search phrase and non-word matches inbetween, e.g. "sol" becomes something like "s[^\w]o[^\w]l"
            const boldCharactersInterspersedWithNonWordMatches = [...p].reduce((result, character, index) => {
                if (index === 0) {
                    return character
                } else {
                    return `${result}\\W*${character}`
                }
            }, '')
            const regex = new RegExp(boldCharactersInterspersedWithNonWordMatches, 'ig')
            const ranges: Range[] = [...lowercaseInputString.matchAll(regex)]
                .map((match) => {
                    if (typeof match.index === 'undefined') {
                        return undefined
                    } else {
                        const range: Range = [match.index, match.index + match[0].length]
                        return range
                    }
                })
                .filter(isNotUndefined)
            return ranges
        })
        .reduce((result, currentStartIndexes) => {
            result.push(...currentStartIndexes)
            return result
        }, [] as Range[])

    const isBold = (index: number) => matchingBoldRanges.some(([rangeStart, rangeEnd]) => index >= rangeStart && index < rangeEnd)

    const result: { part: string; bold: boolean }[] = []

    for (let i = 0; i < inputString.length; i++) {
        const currentIndexIsBold = isBold(i)
        if (result.length === 0 || currentIndexIsBold !== result[result.length - 1].bold) {
            result.push({ part: inputString[i], bold: currentIndexIsBold })
        } else {
            result[result.length - 1].part += inputString[i]
        }
    }

    return result
}
