import { RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { useEffect, useState } from 'react'

const isGiveAwaySelected = (navigator: RangeNavigator) => navigator.selectedValues?.[0]?.label === 'zu verschenken'

export const useGiveAwayToggle = (navigator: RangeNavigator, onSearch: SearchCallback) => {
    const [toggleState, setToggleState] = useState<boolean>(isGiveAwaySelected(navigator))

    // listens to navigator changes globally
    useEffect(() => {
        setToggleState(isGiveAwaySelected(navigator))
    }, [navigator])

    const toggleToggle = async () => {
        setToggleState(!toggleState)
        if (toggleState) {
            await onSearch(navigator.urlConstructionInformation.baseUrl)
        } else {
            await onSearch(navigator.urlConstructionInformation.baseUrl, { PRICE_FROM: '0', PRICE_TO: '0' })
        }
    }

    return { toggleState, toggleToggle }
}
