import { NavigatorGroup } from '@bbx/search-journey/common/Navigators'

export const getNumberOfSelectedTreeAttributes = (navigatorGroups: NavigatorGroup[]) => {
    return navigatorGroups
        .map((group) => group.navigatorList)
        .reduce((a, b) => a.concat(b), [])
        .map((nav) => nav.selectedValues)
        .reduce((a, b) => a.concat(b), [])
        .filter((selectedValue) => selectedValue.urlParamRepresentationForValue[0].urlParameterName === 'treeAttributes').length
}
