import { Navigator, PossibleNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { GroupedPossibleValues } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/GroupedPossibleValues'
import type { useModalNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useModalNavigator'
import { getFlatPossibleValuesForArray, getValueId } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { CheckboxSize } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { Input } from '@wh-components/core/FormElements/Input/Input'
import { Modal } from '@wh-components/core/Modal/Modal'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { matchesSearchTextIndexed, relevantSearchStringParts, splitStringToBoldParts } from '@wh/common/chapter/lib/valueFiltering'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { Fragment, FunctionComponent, KeyboardEvent, useMemo, useState } from 'react'
import { NavigatorProps } from '../NavigatorProps'
import { SearchMultiSelectResetBubbles } from './SearchMultiSelectResetBubbles'

export type NavigatorValueWithSearchStr = { searchStr: string[]; navigatorValue: PossibleNavigatorValue }

export interface NavigatorModalProps {
    label?: string
    modalHeader?: string
    checkboxSize?: CheckboxSize | undefined
    checkedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    uncheckedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    taggingData?: TaggingData
}

type Props = Pick<NavigatorProps, 'navigator' | 'onSearch'> & NavigatorModalProps & ReturnType<typeof useModalNavigator>

export const SearchMultiSelectNavigatorModal: FunctionComponent<Props> = ({
    checkboxState,
    handleChange,
    onSubmitMultiSelect,
    modalOpen,
    closeModal,
    modalHeader,
    navigator,
    checkboxSize,
    checkedContent,
    uncheckedContent,
    setCheckboxState,
    taggingData,
}) => {
    const [value, setValue] = useState('')
    const [usedFilteredValue, setUsedFilteredValue] = useState(false)

    const { combinedValues, filtered, isFiltering } = useFilter(navigator, value)

    const handleReset = () => {
        setCheckboxState((prevState) => {
            const newState = { ...prevState }
            Object.keys(prevState).forEach((key) => (newState[key] = false))
            return newState
        })
    }

    const handleBrandSelection = (id: string, checked: boolean) => {
        if (checked) {
            setValue('')
            setUsedFilteredValue(true)
        }
        return handleChange(id, checked)
    }

    const handleInputEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && isFiltering) {
            const valueId = getValueId(filtered[0])
            handleBrandSelection(valueId, !checkboxState[valueId])
            e.preventDefault()
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (usedFilteredValue) {
            callActionEvent('search_result_list_search_multi_select_submit_with_filter', taggingData)
        } else {
            callActionEvent('search_result_list_search_multi_select_submit', taggingData)
        }
        onSubmitMultiSelect(e)
    }

    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            fullScreen={{ phone: true, tablet: false }}
            width={{ phone: 'auto', tablet: '720px' }}
            header={modalHeader ?? navigator.label}
            testId={`${navigator.id}-modal`}
            height={{ tablet: '100%' }}
            footer={
                <Box display="flex" justifyContent="space-between">
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        marginRight="m"
                        color="complimentary"
                        testId={`${navigator.id}-dismiss-modal`}
                        onClick={closeModal}
                        size={{ phone: 'large', tablet: 'medium' }}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        testId={`${navigator.id}-submit-modal`}
                        onClick={handleSubmit}
                        size={{ phone: 'large', tablet: 'medium' }}
                    >
                        Fertig
                    </Button>
                </Box>
            }
            footerSeparator={true}
            scrollShadow={true}
        >
            <form onSubmit={handleSubmit}>
                <Box paddingBottom="m">
                    <Input
                        id="multi-select-search-input"
                        testId="multi-select-search-input"
                        marginBottom="xs"
                        placeholder="Suchen"
                        aria-label={`Nach ${navigator.label} filtern. Einträge werden während der Eingabe gefiltert.`}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={handleInputEnter}
                    />
                    <SearchMultiSelectResetBubbles
                        navigatorValues={combinedValues}
                        checkboxState={checkboxState}
                        onChange={handleChange}
                        onReset={handleReset}
                    />
                </Box>
                {isFiltering ? (
                    <GroupedPossibleValues
                        navigator={navigator}
                        handleChange={handleBrandSelection}
                        checkboxState={checkboxState}
                        possibleValues={filtered}
                        checkboxSize={checkboxSize}
                        checkedContent={checkedContent}
                        uncheckedContent={uncheckedContent}
                        renderLabel={(label) =>
                            splitStringToBoldParts(label, value).map((p, index) => {
                                if (p.bold) {
                                    return <b key={`${index}${p.part}`}>{p.part}</b>
                                } else {
                                    return <Fragment key={`${index}${p.part}`}>{p.part}</Fragment>
                                }
                            })
                        }
                    />
                ) : (
                    navigator.groupedPossibleValues.map((group) => (
                        <GroupedPossibleValues
                            navigator={navigator}
                            handleChange={handleChange}
                            checkboxState={checkboxState}
                            groupName={group.label}
                            possibleValues={group.possibleValues}
                            key={group.label}
                            checkboxSize={checkboxSize}
                            checkedContent={checkedContent}
                            uncheckedContent={uncheckedContent}
                        />
                    ))
                )}
                <Button type="submit" display="none" />
            </form>
        </Modal>
    )
}

export const useFilter = (navigator: Navigator, searchTerm: string) => {
    const combinedValues = useMemo(
        () =>
            getFlatPossibleValuesForArray(navigator.groupedPossibleValues)
                // Sort and keep "Andere Marken" as the last element
                .sort((a, b) => {
                    if (a.metaTags?.includes('OTHER_VALUE')) {
                        return 1
                    } else if (b.metaTags?.includes('OTHER_VALUE')) {
                        return -1
                    }
                    return a.label.localeCompare(b.label)
                })
                // generate normalized string array and store with value
                .map((navigatorValue) => ({
                    searchStr: relevantSearchStringParts(navigatorValue.label),
                    navigatorValue: navigatorValue,
                })),
        [navigator.groupedPossibleValues],
    )

    const searchParts = relevantSearchStringParts(searchTerm)
    const searchPartsJoined = searchParts.join('')

    const filtered = combinedValues
        .filter(
            (navigatorValue) =>
                matchesSearchTextIndexed(searchParts, navigatorValue.searchStr) ||
                navigatorValue.navigatorValue.metaTags?.includes('OTHER_VALUE'),
        )
        // Move exact matches forward, e.g. "C&A" also matches "Abercrombie & Fitch", but A comes before C in the alphabet
        .sort((a, b) => {
            if (searchPartsJoined === a.searchStr.join('')) {
                return -1
            } else if (searchPartsJoined === b.searchStr.join('')) {
                return 1
            }
            return 0
        })
        .map((navigatorValue) => navigatorValue.navigatorValue)

    const isFiltering = !!searchTerm.trim()

    return { combinedValues, filtered, isFiltering }
}
