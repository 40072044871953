import { SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { ContextLink } from '@bbx/common/types/contextLinks'

interface CategoryRoutingLink {
    label: string
    id?: string
    link: ContextLink | null
}

export const buildParentList = (selectedValues: SelectedNavigatorValue[]): CategoryRoutingLink[] => {
    if (selectedValues.length === 0) {
        return []
    }
    const result: CategoryRoutingLink[] = []

    // CategoryNavigation API returns the label only in the parent subelement but the resetlink is in the current element
    // Therefore we have to take the label from the subelement and the resetlink from the current level
    let currentValue = selectedValues[0]
    while (currentValue.parent) {
        const nextParent = currentValue.parent
        const parentRoutingLink = {
            label: nextParent.label,
            id: nextParent.urlParamRepresentationForValue.find((val) => val.urlParameterName === 'ATTRIBUTE_TREE')?.value,
            link: currentValue.resetLink,
        }
        result.push(parentRoutingLink)
        currentValue = nextParent
    }

    // Since we reassign the currentValue up until the top category we can take the resetlink of the last parent
    result.push({ label: 'Alle Kategorien', link: currentValue.resetLink })

    result.reverse()
    return result
}
