import { AttributeSearchResultItem } from '@bbx/common/types/AttributeSearchResult'
import { searchAttributes } from '@bbx/search-journey/common/api/attributeSearchApiClient'
import { PossibleNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { GroupedPossibleValues } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/GroupedPossibleValues'
import { SearchAttributesMultiSelectResetBubbles } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/SearchAttributesMultiSelectResetBubbles'
import { SearchPossibleValues } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/SearchPossibleValues'
import type { useModalSearchAttributes } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useModalSearchAttributes'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { Input } from '@wh-components/core/FormElements/Input/Input'
import { Modal } from '@wh-components/core/Modal/Modal'
import { Spinner } from '@wh-components/core/Spinner/Spinner'
import { toast } from '@wh-components/core/Toast/Toast'
import { useAbortController } from '@wh/common/chapter/api/useAbortController'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { forwardRef, Fragment, FunctionComponent, KeyboardEvent, useEffect, useRef, useState, useTransition } from 'react'
import { useDebounce } from 'use-debounce'
import { NavigatorProps } from '../NavigatorProps'

type Props = {
    label?: string
    modalHeader?: string
    checkedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    uncheckedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    taggingData?: TaggingData
} & Pick<NavigatorProps, 'navigator' | 'onSearch'> &
    ReturnType<typeof useModalSearchAttributes>

export const SearchAttributesMultiSelectModal: FunctionComponent<Props> = ({
    checkboxState,
    handleChange,
    onSubmitMultiSelect,
    modalOpen,
    closeModal,
    modalHeader,
    navigator,
    checkedContent,
    uncheckedContent,
    setCheckboxState,
    taggingData,
}) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const [inputKey, setInputKey] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [usedFilteredValue, setUsedFilteredValue] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const [initialAttributes, setInitialAttributes] = useState<AttributeSearchResultItem[]>([])
    const [isInitialLoading, setInitialLoading] = useState(false)

    const attributesSearchUrl = navigator.metadata?.attributeSearchLink?.relativePath ?? ''

    const { filteredAttributes, isFiltered } = useFilter(attributesSearchUrl, searchTerm)

    const [_, startTransition] = useTransition()

    const handleReset = () => {
        setCheckboxState((prevState) => {
            const newState = { ...prevState }
            Object.keys(prevState).forEach((key) => (newState[key] = false))
            return newState
        })
    }

    const handleBrandSelection = (id: string, checked: boolean) => {
        startTransition(() => {
            if (checked) {
                setSearchTerm('')
                setInputKey((prev) => prev + 1)
                setUsedFilteredValue(true)
            }
            handleChange(id, checked)
        })
    }

    const handleInputEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (inputRef.current) {
                inputRef.current.blur()
            }
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (usedFilteredValue) {
            callActionEvent('search_result_list_search_multi_select_submit_with_filter', taggingData)
        } else {
            callActionEvent('search_result_list_search_multi_select_submit', taggingData)
        }

        setIsSubmitting(true)

        onSubmitMultiSelect(e).finally(() => {
            setInitialAttributes([])
            setIsSubmitting(false)
        })
    }

    const handleCloseModal = () => {
        setInitialAttributes([])
        closeModal()
    }

    useEffect(() => {
        const loadAttributes = async () => {
            setInitialLoading(true)

            const result = await searchAttributes(attributesSearchUrl)

            setInitialAttributes(result.items)
            setInitialLoading(false)
        }
        if (modalOpen && initialAttributes.length === 0 && !isInitialLoading) {
            loadAttributes()
        }
    }, [attributesSearchUrl, initialAttributes.length, isInitialLoading, modalOpen])

    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={handleCloseModal}
            fullScreen={{ phone: true, tablet: false }}
            width={{ phone: 'auto', tablet: '720px' }}
            header={modalHeader ?? navigator.label}
            testId={`${navigator.id}-modal`}
            height={{ tablet: '100%' }}
            footer={
                <Box display="flex" justifyContent="space-between">
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        marginRight="m"
                        color="complimentary"
                        testId={`${navigator.id}-dismiss-modal`}
                        onClick={handleCloseModal}
                        size={{ phone: 'large', tablet: 'medium' }}
                        disabled={isSubmitting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        flex={{ phone: '1 1 50%', tablet: 'inherit' }}
                        minWidth={{ tablet: 150 }}
                        testId={`${navigator.id}-submit-modal`}
                        onClick={handleSubmit}
                        size={{ phone: 'large', tablet: 'medium' }}
                        disabled={isSubmitting}
                    >
                        Fertig
                    </Button>
                </Box>
            }
            footerSeparator={true}
            scrollShadow={true}
        >
            <form onSubmit={handleSubmit}>
                <Box paddingBottom="m">
                    <DebouncedInput
                        key={inputKey}
                        ref={inputRef}
                        label={navigator.label}
                        onChange={setSearchTerm}
                        onKeyDown={handleInputEnter}
                        disabled={isSubmitting}
                    />
                    <SearchAttributesMultiSelectResetBubbles
                        attributes={initialAttributes}
                        checkboxState={checkboxState}
                        onChange={handleChange}
                        onReset={handleReset}
                    />
                </Box>
                {isFiltered ? (
                    filteredAttributes.length === 0 ? (
                        <Box display="flex" height={200} alignItems="center" justifyContent="center" padding="xl">
                            <Spinner />
                        </Box>
                    ) : (
                        <SearchPossibleValues
                            onChange={handleBrandSelection}
                            navigator={navigator}
                            attributes={filteredAttributes}
                            checkboxState={checkboxState}
                        />
                    )
                ) : (
                    <Fragment>
                        {navigator.groupedPossibleValues.map((group) => (
                            <GroupedPossibleValues
                                navigator={navigator}
                                handleChange={handleChange}
                                checkboxState={checkboxState}
                                groupName={group.label}
                                possibleValues={group.possibleValues}
                                key={group.label}
                                checkedContent={checkedContent}
                                uncheckedContent={uncheckedContent}
                            />
                        ))}
                        {isInitialLoading ? (
                            <Box display="flex" height={200} alignItems="center" justifyContent="center" padding="xl">
                                <Spinner />
                            </Box>
                        ) : (
                            <SearchPossibleValues
                                groupName="Alle"
                                navigator={navigator}
                                onChange={handleChange}
                                attributes={initialAttributes}
                                checkboxState={checkboxState}
                            />
                        )}
                    </Fragment>
                )}
                <Button type="submit" display="none" />
            </form>
        </Modal>
    )
}

type DebouncedInputProps = {
    label: string
    onChange: (value: string) => void
    disabled: boolean
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
}

const DebouncedInput = forwardRef<HTMLInputElement, DebouncedInputProps>(({ label, onChange, disabled, onKeyDown }, ref) => {
    const [value, setValue] = useState('')
    const [debouncedValue] = useDebounce(value, 350)

    useEffect(() => {
        onChange(debouncedValue)
    }, [debouncedValue, onChange])

    return (
        <Input
            ref={ref}
            id="multi-select-search-input"
            testId="multi-select-search-input"
            marginBottom="xs"
            placeholder="Suchen"
            aria-label={`Nach ${label} filtern. Einträge werden während der Eingabe gefiltert.`}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={onKeyDown}
            disabled={disabled}
        />
    )
})

export const useFilter = (url: string, searchTerm: string) => {
    const [filteredAttributes, setFilteredAttributes] = useState<AttributeSearchResultItem[]>([])
    const { createAbortSignal, abort } = useAbortController()

    useEffect(() => {
        const filterAttributes = async (term: string) => {
            try {
                abort()
                const result = await searchAttributes(url, createAbortSignal(), encodeURIComponent(term.trim()))
                setFilteredAttributes(result.items)
            } catch (e) {
                const apiError = wrapInApiErrorIfNecessary(e)
                if (!apiError.isAbort) {
                    toast(<ApiErrorAlert error={apiError.errorResponse} />, {
                        type: 'error',
                    })
                }
            }
        }
        if (searchTerm.trim() !== '') {
            filterAttributes(searchTerm)
        } else {
            setFilteredAttributes([])
        }
    }, [abort, createAbortSignal, searchTerm, url])

    const isFiltered = searchTerm ? !!searchTerm.trim() : false

    return { filteredAttributes, isFiltered }
}
