import { Navigator, NavigatorId, PossibleNavigatorValue, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { NavigatorModalProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/ModalNavigatorModal'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import {
    SelectedValues,
    SelectedValuesProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/SelectedValues'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { WidthProps } from '@wh-components/system/layout'
import { sortAlphabetically } from '@wh/common/chapter/lib/commonHelpers'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import React, { Fragment, FunctionComponent } from 'react'
import { getSelectedValuesTextDefault, ModalNavigatorButton } from './ModalNavigator'
import { SearchAttributesMultiSelectModal } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/SearchAttributesMultiSelectModal'
import { useModalSearchAttributes } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useModalSearchAttributes'

export interface SearchAttributesMultiSelectProps {
    id?: NavigatorId
    showSelectedValues?: boolean
    disabled?: boolean
    selectedValueInColumns?: boolean
    testIdFragmentOverride?: string
    taggingEvent?: TaggingActionEvent
    getSelectedValuesText?: (navigator: Navigator | RangeNavigator | undefined) => string
    showHelpText?: boolean
    checkedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    uncheckedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
}

export const SearchAttributesMultiSelect: FunctionComponent<
    Omit<NavigatorProps, 'navigator'> & { navigator: Navigator | undefined } & NavigatorModalProps &
        SearchAttributesMultiSelectProps &
        SelectedValuesProps &
        Partial<Pick<WidthProps, 'width' | 'maxWidth'>>
> = ({
    id,
    navigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    label = navigator?.label,
    modalHeader,
    showSelectedValues = true,
    sortSelectedValues = true,
    width = '100%',
    disabled = false,
    maxWidth,
    selectedValueInColumns = false,
    testIdFragmentOverride,
    taggingEvent,
    taggingData,
    getSelectedValuesText = getSelectedValuesTextDefault,
    showHelpText = false,
    checkedContent,
    uncheckedContent,
}) => {
    const { checkboxState, handleChange, onSubmitMultiSelect, modalOpen, openModal, closeModal, setCheckboxState } =
        useModalSearchAttributes(navigator, onSearch, taggingData)
    const navigatorId = navigator?.id ?? id
    const navigatorLabelId = `navigator-title-${navigatorId}`
    const combinedAriaLabelledBy = [ariaLabelledBy, navigatorLabelId].filter(Boolean).join(' ')

    const hasSelectedValues = (navigator?.selectedValues.length ?? 0) > 0
    const buttonText = (!showSelectedValues && hasSelectedValues ? getSelectedValuesText(navigator) : label) ?? ''

    return (
        <Fragment>
            {navigator && (
                <SearchAttributesMultiSelectModal
                    checkboxState={checkboxState}
                    handleChange={handleChange}
                    onSubmitMultiSelect={onSubmitMultiSelect}
                    modalOpen={modalOpen}
                    openModal={openModal}
                    closeModal={closeModal}
                    modalHeader={modalHeader}
                    navigator={navigator}
                    onSearch={onSearch}
                    setCheckboxState={setCheckboxState}
                    checkedContent={checkedContent}
                    uncheckedContent={uncheckedContent}
                    taggingData={taggingData}
                />
            )}
            {showHelpText && (
                <Text display="block" marginBottom="xs" fontWeight="semibold">
                    {buttonText}
                </Text>
            )}
            <Box borderTopRadius="m">
                <ModalNavigatorButton
                    testIdFragment={(testIdFragmentOverride || navigatorId) ?? ''}
                    width={width}
                    maxWidth={maxWidth}
                    openModal={openModal}
                    hasSelectedValues={hasSelectedValues}
                    disabled={disabled}
                    buttonText={`${buttonText}${showHelpText ? ' wählen...' : ''}`}
                    taggingEvent={taggingEvent}
                    taggingData={taggingData}
                />
                {showSelectedValues && hasSelectedValues && navigator && (
                    <Box backgroundColor={showSelectedValues && hasSelectedValues && 'palette.polarbear'}>
                        <SelectedValues
                            showInColumns={selectedValueInColumns}
                            sortSelectedValues={sortSelectedValues ? (a, b) => sortAlphabetically(a.label, b.label) : undefined}
                            navigator={navigator}
                            onSearch={onSearch}
                            aria-labelledby={combinedAriaLabelledBy}
                            taggingData={taggingData}
                        />
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}
