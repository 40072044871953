import { CheckboxStateType, getValueId } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import React, { FunctionComponent } from 'react'
import { ResetLinkButton } from '../ResetLinkButton/ResetLinkButton'
import { NavigatorValueWithSearchStr } from './SearchMultiSelectNavigatorModal'

type SearchMultiSelectResetBubblesProps = {
    navigatorValues: NavigatorValueWithSearchStr[]
    checkboxState: CheckboxStateType
    onChange: (id: string, value: boolean) => void
    onReset: () => void
}

export const SearchMultiSelectResetBubbles: FunctionComponent<SearchMultiSelectResetBubblesProps> = ({
    navigatorValues,
    checkboxState,
    onChange,
    onReset,
}) => {
    const anythingSelected = Object.keys(checkboxState).reduce((accumulator, current) => accumulator || checkboxState[current], false)

    return (
        <Box display="flex" flexWrap="wrap" gap="xs">
            {navigatorValues
                .filter((elem) => checkboxState[getValueId(elem.navigatorValue)])
                .map((elem) => (
                    <ResetLinkButton
                        testId="search-multiselect-reset-bubbles"
                        key={getValueId(elem.navigatorValue)}
                        label={elem.navigatorValue.label}
                        onClick={() => {
                            onChange(getValueId(elem.navigatorValue), false)
                        }}
                    />
                ))}
            {anythingSelected && (
                <ButtonWithLinkStyle testId="search-multiselect-reset-all-bubbles" onClick={onReset} marginLeft="xs" fontWeight="bold">
                    Filter zurücksetzen
                </ButtonWithLinkStyle>
            )}
        </Box>
    )
}
